import React from "react"
import {graphql} from "gatsby"
import Image from "gatsby-image"

// We're using Gutenberg so we need the block styles
import "@wordpress/block-library/build-style/style.css"
import "@wordpress/block-library/build-style/theme.css"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PostBox from "../components/post-box";

const AuthorPage = ({data}) => {
  const {firstName, lastName, description} = data.wpUser;
  const {facebook, linkedin, twitter, website, position, photo} = data.wpUser.author_info;
  const posts = data.allWpPost.nodes;
  const image = photo?.localFile?.childImageSharp?.fluid;

  const schema = {
    "@context": "https://schema.org/",
    "@type": "Person",
    "name": firstName + ' ' + lastName,
    "url": "Url",
    "image": image?.src,
    "sameAs": [
      facebook,
      linkedin,
      twitter,
      website
    ],
    "jobTitle": position,
    "worksFor": {
      "@type": "Organization",
      "name": "Derave Software"
    }
  }

  return (
    <Layout>
      <SEO
        title={firstName + ' ' + lastName + ' profile information'}
        description={firstName + ' ' + lastName + ' profile information'}
        schema={schema}
      />

      <section className="author-page">
        <div className="container-fluid">
          <div className="row columns-8">
            <div className="author-page__photo">
              <Image
                fluid={image}
                alt={photo?.altText || ''}
              />
            </div>
            <div className="author-page__info">
              <h1 className="author-page__title h1">{firstName} {lastName}</h1>
              <div className="author-page__position">{position}</div>
              <div className="author-page__photo-mobile">

              </div>
              <div className="author-page__bio">{description || null}</div>
              <div className="author-page__socials">
                {linkedin && <div className="author-page__social author-page__social--linkedin">
                  <a title="LinkedIn" rel="nofollow noopener" target="_blank" href={linkedin}/>
                </div>}
                {facebook && <div className="author-page__social author-page__social--fb">
                  <a title="Facebook" rel="nofollow noopener" target="_blank" href={facebook}/>
                </div>}
                {twitter && <div className="author-page__social author-page__social--twitter">
                  <a title="Twitter" rel="nofollow noopener" target="_blank" href={twitter}/>
                </div>}
                {website && <div className="author-page__social author-page__social--website">
                  <a title="Website" rel="nofollow noopener" target="_blank" href={website}/>
                </div>}
              </div>
            </div>

          </div>
        </div>
      </section>


      <section className="article-index">
        <div className="container-fluid">
          <h2 className="article-index__title">Author posts</h2>

          <div className="row article-index__row">
            {posts.map(post => {
              return <PostBox key={post.slug} hideAuthor={true} post={post} />
            })}
          </div>

          {/*<div className="row columns-8">
            <div className="article-index__button">
              <Link to="/blog" className="btn-outline">SEE MORE</Link>
            </div>
          </div>*/}
        </div>
      </section>
    </Layout>
  )
}

export default AuthorPage


export const pageQuery =  graphql`
query MyQuery($id: String, $slug: String) {
  wpUser(id: { eq: $id }) {
      firstName
      lastName
      email
      username
      nicename
      slug
      description
      author_info {
          facebook
          linkedin
          position
          twitter
          website
          photo {
              id
              altText
              localFile {
                  childImageSharp {
                      fluid(maxWidth: 435, maxHeight: 435, quality: 100) {
                          ...GatsbyImageSharpFluid_withWebp
                      }
                  }
              }
          }
      }
  }
    allWpPost(limit: 8, filter: {author: {node: {slug: {eq: $slug}}}}) {
        nodes {
          excerpt
          title
          slug
          modified
          modifiedGmt
          date
          dateGmt
          content
          uri

          terms {
              nodes {
                  name
                  slug
              }
          }

          featuredImage {
              node {
                  altText
                  localFile {
                      childImageSharp {
                          fluid(maxWidth: 650, maxHeight: 364, quality: 100) {
                              ...GatsbyImageSharpFluid_withWebp
                          }
                      }
                  }
              }
          }

          author {
              node {
                  firstName
                  lastName
                  slug
              }
          }
        }
    }
}
`
