import React from "react";

import {Link} from "gatsby";
import parse from "html-react-parser";
import Image from "gatsby-image";
import Categories from "./blog/categories";

const PostBox = ({ post, hideAuthor }) => {

  const author = post.author.node.firstName + ' ' + post.author.node.lastName;
  const username = post.author.node.slug;
  const featuredImage = {
    fluid: post.featuredImage?.node?.localFile?.childImageSharp?.fluid,
    alt: post.featuredImage?.node?.alt || ``
  }

  return (
    <article className="article-box">

      {featuredImage.fluid && <div className="article-box__thumbnail">
        <Link aria-label={`Read more about ${post.title}`} to={post.uri}>
          <Image
            fluid={featuredImage.fluid}
            alt={featuredImage.alt}
          />
        </Link>
      </div>}

      <Categories terms={post.terms.nodes}/>

        <h3 className="article-box__title">
          <Link to={post.uri}>{parse(post.title)}</Link>
        </h3>
      {!hideAuthor && <span className="article-box__author">
        <span className="article-box__prefix">By</span>&nbsp;
          <Link to={'/author/' + username}>{author}</Link>
        </span>}

        <div className="article-box__description">{parse(post.excerpt)}</div>

        <div className="article-box__readmore">
          <Link aria-label={`Read more about ${post.title}`} to={post.uri}>Read more</Link>
        </div>

    </article>
  )
}

export default PostBox
