import React from "react";
import {Link} from "gatsby";

const Categories = ({terms}) => {

  return (
    <div className="hashtags">
        {terms.map(term => {
          return <span key={term.slug} className="hashtags__single"><Link to={'/tag/' + term.slug}>#{term.name}</Link></span>
        })}
    </div>
  )
}

export default Categories
